import {
  Github,
  Twitter,
  Behance,
  Linkedin,
  Instagram,
} from 'styled-icons/boxicons-logos';

const Icons = {
  Github,
  Twitter,
  Behance,
  Linkedin,
  Instagram,
};

export default Icons;
