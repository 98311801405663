const links = [
  {
    label: 'Benove Studio',
    url: '/benove/',
  },
  {
    label: 'LI - Destaque stories',
    url: '/plugin-destaques-stories/',
  },
];

export default links;
